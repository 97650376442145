// Affichez les 12 premiers cahiers
let oneCahier = document.querySelectorAll(".one-cahier");
let index = 0;

for (let i = index; i < 12 && i < oneCahier.length; i++) {
    oneCahier[i].style.display = "block";
    index++;
}

let loadMoreBtn = document.querySelector("#load-more-btn");
if(loadMoreBtn !== null) {
	loadMoreBtn.addEventListener("click", function (e) {
		e.preventDefault();
		let cahiersCaches = document.querySelectorAll(".one-cahier:not([style='display:block'])");
		// Affichez les 18 cahiers suivants
		for (let i = index; i < (index + 18) && i < cahiersCaches.length; i++) {
			cahiersCaches[i].style.display = "block";
		}
		index+=18;
		// Vérifiez si tous les cahiers sont affichés
		let tousCahiersVisibles = Array.from(cahiersCaches).every(function (element) {
			return window.getComputedStyle(element).display === "block";
		});
		// Si tous lescahiers sont affichés, masquez le bouton
		if (tousCahiersVisibles) {
			loadMoreBtn.style.display = "none";
		}
	});
}